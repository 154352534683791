




























import { Component, Prop, Vue } from 'vue-property-decorator';

import Attachment from '@/api/models/attachment';
import CoreFileInput from '@/components/utils/CoreFileInput.vue';

import { AttachmentInputResolver } from './AttachmentInput';

@Component({
  components: {
    CoreFileInput
  }
})
export default class AttachmentInput extends Vue {
  /**
   * v-model用。このコンポーネントは、Attachmentオブジェクトを入出力としてあつかう。
   * 
   * 「選択されたファイル名」は、必ずAttacment内のものを利用し、S3へのアップロードが成功した場合のみ、
   * ファイル名として表示するようにする。
   */
  @Prop({ required: true })
  value?: Attachment; // ファイルが無い場合もある

  /**
   * Attachment登録メソッド
   * 登録に成功したときAttachmentを返す。失敗したときは値を返さない。
   */
  @Prop({ required: true })
  resolver!: AttachmentInputResolver;

  /**
   * バリデーションメソッド
   */
  @Prop({ required: true })
  validation!: (file: File) => string;

  error = '';

  async onUpload(file: File) {
    this.error = this.validation(file);
    if (this.error) {
      return;
    }
    const attachment = await this.resolver(file);
    if (!attachment) return;
    this.$emit('input', attachment);
  }

  onClear() {
    this.$emit('input', undefined);
  }
}
