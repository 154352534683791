




































































import _ from 'lodash';
import { Component, Inject, Mixins, Prop } from 'vue-property-decorator';

import Attachment from '@/api/models/attachment';
import CaseStudy from '@/api/models/case_study';
import Tag from '@/api/models/tag';
import CoreFooter from '@/components/utils/CoreFooter.vue';
import DeleteConfirmFormview from '@/components/utils/DeleteConfirmFormview.vue';
import LeaveConfirmFormview from '@/components/utils/LeaveConfirmFormview.vue';
import Validatable from '@/mixins/validatable';
import { GlobalLoadingScreenModule } from '@/store/global_loading_screen';
import { GlobalMessageModule } from '@/store/global_message';
import { changedForm, Form } from '@/utils/form';

import CaseStudyBaseInput from './CaseStudyBaseInput.vue';
import CaseStudyTagInput from './CaseStudyTagInput.vue';

@Component({
  components: {
    CaseStudyBaseInput,
    CaseStudyTagInput,
    CoreFooter,
    DeleteConfirmFormview,
    LeaveConfirmFormview,
  }
})
export default class CaseStudyInputTemplate extends Mixins(Validatable) {  
  @Inject()
  globalLoadingScreenModule!: GlobalLoadingScreenModule;

  @Inject()
  globalMessageModule!: GlobalMessageModule;

  /**
   * 編集対象となるCaseStudyのオブジェクト
   */
  @Prop({ required: true })
  form!: Form<CaseStudy>;

  /**
   * 選択対象となるタグ一覧のオブジェクト
   */
  @Prop({ required: true})
  tags!: Tag[];

  /**
   * 削除ボタンを表示するか否か
   */
  @Prop({ default: false})
  withDeleteButton?: boolean;

  /**
   * postAttachment APIコールバック
   */
  @Prop({ required: true })
  postAttachment!: (fileName: string) => Promise<Attachment>;

  /**
   * putFile APIコールバック
   */
  @Prop({ required: true })
  putFile!: (url: string, file: File) => Promise<void>;

  active = false;
  deleteDialog = false;
  leaveDialog = false;
  errors: Partial<Record<keyof CaseStudy, string>> = {};

  onOpenLeaveDialog() {
    if (!changedForm(this.form)) {
      this.$emit('cancel');
    } else {
      this.leaveDialog = true;
    }
  }

  onDelete() {
    this.$emit('delete');
    this.deleteDialog = false;
  }

  async onSubmit() {
    this.errors = await this.validate(this.form);
    if (_.isEmpty(this.errors)) {
      this.$emit('save');
    }
  }

  async registerAttachment(file: File) {
    if (this.active) return;
    this.active = true;
    
    try {
      const results =  await this.globalLoadingScreenModule.track(
        this.prepareAttachment(file)
      );
      return results;
    } catch (error) {
      this.globalMessageModule.addMessages('error', ['ファイルのアップロードに失敗しました。'], 'now');
    } finally {
      this.active = false;
    }
  }

  private async prepareAttachment(file: File) {
    const attachment =  await this.postAttachment(file.name);
    if (!attachment.s3PresignedUri) return;
    await this.putFile(attachment.s3PresignedUri, file);

    attachment.file = file;
    attachment.fileName = file.name;
    return attachment;
  }
}
