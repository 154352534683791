

















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Ref } from 'vue-property-decorator';

@Component
export default class CoreFileInput extends Vue {

  @Ref('handler')
  handler!: HTMLInputElement;

  @Prop({ default: () => [] })
  accept!: string[];

  @Prop({ default: false })
  disabled!: boolean;

  @Prop({ default: true })
  multiple!: boolean;

  onSelect(event: InputEvent) {
    const target = event.target;

    if (!target) {
      return;
    }

    const files = (target as HTMLInputElement).files;

    if (!files || files.length === 0) {
      return;
    }

    if (this.multiple) {
      this.$emit('upload', Array.from(files)); // FileListからFile[]に変換
    } else {
      this.$emit('upload', files[0]);
    }

    // <input>のインターナルステートの初期化も必要だ。
    // この分がないと、２度と使えなくなってしまう。
    this.handler.value = '';
  }
}
