










import { Component, Prop, Vue } from 'vue-property-decorator';

import Tag from '@/api/models/tag';
import TagIcon from '@/components/utils/TagIcon.vue';

@Component({
  components: {
    TagIcon,
  }
})
export default class TagTitleSimple extends Vue {

  /**
   * タグ
   */
  @Prop({ required: true})
  tag!: Tag;

}
