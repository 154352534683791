



















import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';

import Tag from '@/api/models/tag';
import TagButton from '@/components/utils/TagButton.vue';
import TagTitleSimple from '@/components/utils/TagTitleSimple.vue';

@Component({
  components: {
    TagButton,
    TagTitleSimple,
  }
})
export default class SimpleTagSection extends Vue {

  /**
   * セクションに表示するタグ
   */
  @Prop({ required: true })
  tag!: Tag;

  /**
   * v-model用
   */
  @Prop({ required: true })
  value!: string[];

  /**
   * 一つ選択
   */
  @Prop({ default: false })
  selectOne!: boolean;

  onInput(nextValue: string[]) {
    const nextSelected = this.tag.findDts(nextValue);

    if (!this.selectOne || nextSelected.length <= 1) {
      this.$emit('input', nextValue);
      return;
    }

    // selectOneかつ1つ以上選択された場合
    // 新しく選択されたuuidを残して前のチェックを削除
    const prevSelected = _.map(this.tag.findDts(this.value), 'uuid'); 
    this.$emit('input', _.difference(nextValue, prevSelected));
  }

}
