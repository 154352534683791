



















import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ja';

import Errorable from '@uniquevision/libraries.beluga_ui/src/mixins/errorable';
import dayjs, { Dayjs } from 'dayjs';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import DatePicker from 'vue2-datepicker';;

@Component({
  components: {
    DatePicker,
  }
})
export default class CoreDatePicker extends Mixins(Errorable) {

  /**
   * v-model用
   */
  @Prop({ required: true })
  value?: Dayjs;

  /**
   * 入力可能かどうか
   */
  @Prop({ default: false })
  disabled!: boolean;

  focused = false;

  get date() {
    if (this.value) {
      return this.value.toDate();
    }
  }

  set date(value: Date | undefined) {
    this.$emit('input', value ? dayjs(value) : undefined);
  }

  onFocus(event: Event) {
    this.focused = true;

    /**
     * inputのfocusイベント
     */
    this.$emit('focus', event);
  }
  onBlur(event: Event) {
    this.focused = false;

    /**
     * inputのblurイベント
     */
    this.$emit('blur', event);
  }
}
