














































































import { Component, Prop, Vue } from 'vue-property-decorator';

import CaseStudy from '@/api/models/case_study';
import AttachmentInput from '@/components/utils/AttachmentInput.vue';
import CoreDatePicker from '@/components/utils/CoreDatePicker.vue';
import CoreSection from '@/components/utils/CoreSection.vue';
import FieldGroup from '@/components/utils/FieldGroup.vue';
import FieldRow from '@/components/utils/FieldRow.vue';
import { KbnConst, PublishKbn } from '@/utils/kbn';

import { AttachmentInputResolver } from './utils/AttachmentInput';

const MAX_FILE_SIZE = 10_000_000;

@Component({
  components: {
    AttachmentInput,
    CoreSection,
    FieldRow,
    FieldGroup,
    CoreDatePicker,
  }
})
export default class CaseStudyBaseInput extends Vue {
  /**
   * v-model用。CaseStudyオブジェクト
   */
  @Prop({ required: true})
  value!: CaseStudy;

  /**
   * 添付ファイル登録メソッド
   */
  @Prop({ required: true})
  registerAttachment!: AttachmentInputResolver;

  /**
   * エラー情報
   */
  @Prop({  default: () => ({}) } )
  errors!: Partial<Record<keyof CaseStudy, string>>;

  accept = ['.pdf'];

  get publish(): PublishKbn {
    return KbnConst.PUBLISH_KBN_PUBLIC;
  }

  get nonPublish(): PublishKbn {
    return KbnConst.PUBLISH_KBN_NON_PUBLIC;
  }

  mainAttachmentValidation(file: File) {
    // ファイルサイズ
    if (file.size > MAX_FILE_SIZE) {
      return this.$t('error.00207', {fileSize: '10MB'});
    }

    if (!file.name.match(/.pdf$/)) {
      return this.$t('error.00208', {fileExtension: '.pdf'});
    }

    return '';
  }

}
