













import { Component, Prop, Vue } from 'vue-property-decorator';

import TagDt from '@/api/models/tag_dt';
import { TagCardVariant } from '@/components/utils/TagCard';
import TagCard from '@/components/utils/TagCard.vue';
import TagIcon from '@/components/utils/TagIcon.vue';

@Component({
  components: {
    TagIcon,
    TagCard
  }
})
export default class TagButton extends Vue {

  /**
   * v-model用 配列に選択要素を追加する
   */
  @Prop({ required: true })
  value!: string[];

  /**
   * タグ詳細
   */
  @Prop({ required: true })
  tagDt!: Required<TagDt>;

  /**
   * ボタンの表示形式
   */
  @Prop({ required: true })
  variant!: TagCardVariant;

  /**
   * 無効
   */
  @Prop({ default: false })
  disabled!: boolean;

  get checked() {
    return this.value.indexOf(this.tagDt.uuid) !== -1;
  }

  onToggle() {
    if (this.disabled) return;

    // inputイベントで入力を制御したいのでthis.value.push等の直接操作で返さない。
    if (!this.checked) {
      this.$emit('input', [...this.value, this.tagDt.uuid]);

    } else {
      /**
       * v-model用
       */
      this.$emit('input', this.value.filter(candidate => candidate !== this.tagDt.uuid));
    }
  }

}
