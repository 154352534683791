



















import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';

import Tag from '@/api/models/tag';
import CoreSection from '@/components/utils/CoreSection.vue';
import SimpleTagSection from '@/components/utils/SimpleTagSection.vue';
import { TagKeyCode } from '@/utils/key_code';

@Component({
  components: {
    CoreSection,
    SimpleTagSection
  }
})
export default class CaseStudyTagInput extends Vue {
  /**
   * v-model用
   */
  @Prop({ required: true })
  value!: string[];

  /**
   * タグ
   */
  @Prop({ required: true})
  tags!: Tag[];

  get selectOneByUuid() {
    return _.chain(this.tags)
      .keyBy('uuid')
      .mapValues( tag => tag.keyCode === TagKeyCode.INDUSTRY ) // industoryだけ１つ選択
      .value();
  }

}
